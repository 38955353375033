import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

import Quill from 'quill'
const QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter

import ImageResize from 'quill-image-resize-module';
 
// console.log(ImageResize);
Quill.register('modules/imageResize', ImageResize);

@Component({
	
})
export default class TextEditor2 extends Vue {
  @Prop() data!: string;
  @Prop() value!: string;

  editor!: Quill;
  content = {
    ops: {}
  };
  contentText = '';
  countChange = 0;

  mounted() {
    this.mountEditor();
  }

  mountEditor(){
    this.editor = new Quill('#editor', {
      modules: {
        toolbar: this.toolbarOptions0,
        imageResize: {
          displaySize: true,
          // modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
          modules: [ 'Toolbar' ],
          handleStyles: {
            backgroundColor: 'black',
            border: 'none',
            color: 'white'
          }
        }
      },
      placeholder: 'Descrição...',
      theme: 'snow'  // or 'bubble'
    });
    this.editor.on('text-change', this.textChange)
  }

  textChange(delta: any, oldContents: any, source: string){
    this.content = this.editor.getContents();
    this.contentText = this.convertDeltaToHtml(this.content.ops)
    this.$emit('input', this.contentText);
    // this.value = this.contentText
    // this.emitChangeTextHtml(this.contentText)
    // console.log(delta);
    // console.log(oldContents);
    // console.log(source);
    // this.contentText = this.editor.root.innerHTML;
    // this.contentText = this.editor.getText();
  }

  emitChangeTextHtml(content: any){
    this.$emit('changeTextHtml', content)
  }

  convertDeltaToHtml(delta: any){
    const cfg = {};
    const converter = new QuillDeltaToHtmlConverter(delta, cfg);
    const html = converter.convert(); 
    return html;
  }

  setContentFromData(newContent: any){
    const delta = this.editor.clipboard.convert(newContent);
    this.editor.setContents(delta, 'silent');
    // this.editor.setText(newContent, 'silent');
    // const delta = this.editor.clipboard.convert(this.data)
    // this.editor.setText(this.data);
    // this.editor.root.innerHTML = this.data;
    // const delta = this.editor.clipboard.dangerouslyPasteHTML(this.data);
    // console.log(delta);
  }

  @Watch('value', { 
    immediate: false, deep: false 
  })
  changeData(newValue: any){
    if( this.countChange == 0){
      this.setContentFromData(newValue)
    }
    this.countChange = this.countChange +1
    // console.log(this.countChange);
    
  }

  toolbarOptions0 = [
    ['bold', 'italic', 'underline', 'link']
  ]

  toolbarOptions1 = [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    ['image', 'code-block', 'video']
  ]

  toolbarOptions2 = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block', 'link'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean'],                                         // remove formatting button

    ['image', 'code-block'],
    ['video']
  ];
  

}
