import { Alert } from '@/services/notification';

import { 
  parseDateToDatetimeEnUs,
  convertDataHourPtBr2 } from '@/utils';

export class ConcursosService {
	store: any
	alert: Alert
  totalItensProcess: any = 0

	constructor(store: any){
		this.store = store
		this.alert = new Alert(this.store)
	}

	treatListReceive(list: any){
    return list.map( (item: any) => {
      item.datacadastro = convertDataHourPtBr2(item.datacadastro)
      return item;
    })
  }

  processListAnexosReceive(list: any){
    return list.map( (item: any) => {
      item.data_publicacao = new Date(item.data_publicacao);
      // item.data = parseStrEnUsDate(item.data);
      return item;
    })
  }

  treatToSendData(item: any){
    item.datacadastro = parseDateToDatetimeEnUs(item.datacadastro)
    if(item.extrafields != undefined && Object.keys(item.extrafields).length == 0){
      delete item.extrafields;
    }
    item.extrafields = JSON.stringify(item.extrafields)
    return item;
  }

  treatReceiveData(item: any){
    item.datacadastro = new Date(item.datacadastro)
    console.log(item.extrafields);
    
    if(item.extrafields != undefined && item.extrafields != null){
      item.extrafields = JSON.parse(item.extrafields);
      // item.extrafields.situacao = ''
    }
    else{
      item.extrafields = {}
    }
    return item;
  }
}